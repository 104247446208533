<template>
    <div class="create-user-modal">
        <BaseHeader
            :title="user ? $t('titleEdit') : $t('title')"
            @close="$emit('close')"
        />

        <div class="l-padded create-user-modal__content">
            <div class="l-stack l-gap-2 settings-form">
                <label v-if="!user">
                    <p v-if="errors.username" class="form-error">
                        {{ errors.username }}
                    </p>

                    <BaseInput
                        v-model="username"
                        :placeholder="$t('username')"
                    />
                </label>

                <label>
                    <p v-if="errors.first_name" class="form-error">
                        {{ errors.first_name }}
                    </p>

                    <BaseInput
                        v-model="firstName"
                        :placeholder="$t('firstName')"
                    />
                </label>

                <label>
                    <p v-if="errors.last_name" class="form-error">
                        {{ errors.last_name }}
                    </p>

                    <BaseInput
                        v-model="lastName"
                        :placeholder="$t('lastName')"
                    />
                </label>

                <label>
                    <p
                        v-if="
                            ($v.email.$anyError && $v.email.$dirty) ||
                                errors.email
                        "
                        class="form-error"
                    >
                        {{ $t('emailInvalid') }}
                    </p>

                    <BaseInput
                        v-model="$v.email.$model"
                        :placeholder="$t('email')"
                    />
                </label>

                <label v-if="!user">
                    <p v-if="errors.password" class="form-error">
                        {{ errors.password }}
                    </p>

                    <BaseInput
                        v-model="password"
                        type="password"
                        :placeholder="$t('password')"
                    />
                </label>

                <BaseMultiselect
                    v-model="role"
                    :options="roleOptions"
                    label="name"
                    track-by="id"
                    :placeholder="$t('role')"
                />

                <BaseMultiselect
                    v-model="language"
                    :options="languageOptions"
                    label="label"
                    track-by="value"
                    :placeholder="$t('language')"
                />

                <label class="l-inline l-gap-1 l-center-v clickable">
                    <ToggleButton v-model="isAdmin" redesigned />

                    <span>{{ $t('admin') }}</span>
                </label>
            </div>
        </div>

        <footer
            class="l-padded l-inline l-justify-end create-user-modal__actions"
        >
            <BaseButton
                size="small"
                :disabled="isDisabled"
                :is-loading="isLoading"
                @click="handleSubmit"
            >
                {{ user ? $t('submitEdit') : $t('submit') }}
            </BaseButton>
        </footer>
    </div>
</template>

<script>
import { email } from 'vuelidate/lib/validators'

import { httpHelper } from '@/utils'
import BaseButton from './BaseButton'
import BaseHeader from './BaseHeader'
import BaseInput from './BaseInput'
import BaseMultiselect from './BaseMultiselect'
import ToggleButton from '../ToggleButton'

export default {
    name: 'UserEditModal',
    components: {
        BaseButton,
        BaseHeader,
        BaseInput,
        BaseMultiselect,
        ToggleButton,
    },
    props: {
        onSubmit: {
            type: Function,
            default: null,
        },
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            email: '',
            errors: {},
            firstName: '',
            isAdmin: false,
            isLoading: false,
            language: null,
            languageOptions: [
                { label: this.$t('shared.language.de'), value: 'de' },
                { label: this.$t('shared.language.en'), value: 'en' },
                { label: this.$t('shared.language.fr'), value: 'fr' },
                { label: this.$t('shared.language.it'), value: 'it' },
            ],
            lastName: '',
            password: '',
            role: null,
            roleOptions: [],
            username: '',
        }
    },
    computed: {
        isDisabled() {
            return this.$v.$invalid || this.user
                ? !this.email ||
                      !this.firstName ||
                      !this.language ||
                      !this.lastName
                : !this.email ||
                      !this.firstName ||
                      !this.language ||
                      !this.lastName ||
                      !this.password ||
                      !this.username
        },
    },
    watch: {
        email() {
            this.errors.email = null
        },
        firstName() {
            this.errors.first_name = null
        },
        lastName() {
            this.errors.last_name = null
        },
        password() {
            this.errors.password = null
        },
        user: {
            immediate: true,
            handler() {
                this.email = this.user?.email
                this.firstName = this.user?.first_name
                this.language = this.languageOptions.find(
                    ({ value }) => value === this.user?.language
                )
                this.lastName = this.user?.last_name
                this.role = this.roleOptions.find(
                    ({ id }) => id === this.user?.role
                )
            },
        },
        username() {
            this.errors.username = null
        },
    },
    validations() {
        return {
            email: {
                email,
            },
        }
    },
    async mounted() {
        try {
            this.isLoading = true
            const { data } = await httpHelper.get('/roles/')
            this.roleOptions = data.results
            if (this.user) {
                this.role = this.roleOptions.find(
                    ({ id }) => id === this.user.role
                )
            }
        } finally {
            this.isLoading = false
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.isLoading = true
                const data = {
                    email: this.email,
                    first_name: this.firstName,
                    is_account_admin: this.isAdmin,
                    language: this.language?.value,
                    last_name: this.lastName,
                    role: this.role?.id,
                }
                if (this.user) {
                    await httpHelper.patch(`/users/${this.user.id}/`, data)
                } else {
                    await httpHelper.post('/users/', {
                        ...data,
                        password: this.password,
                        platform: 'ax-track',
                        username: this.username,
                    })
                }
                this.onSubmit?.()
                this.$emit('close')
            } catch (e) {
                this.errors = Object.entries(e.response?.data || {}).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key]: Array.isArray(value) ? value[0] : value,
                    }),
                    {}
                )
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "actions": "Actions",
        "admin": "Admin",
        "email": "Email",
        "emailInvalid": "Invalid E-Mail",
        "firstName": "First Name",
        "language": "Language",
        "lastName": "Last Name",
        "password": "Password",
        "role": "Role",
        "submit": "Create",
        "submitEdit": "Apply",
        "title": "Create user",
        "titleEdit": "Edit user",
        "username": "Username"
    },
    "de": {
        "actions": "Aktionen",
        "admin": "Admin",
        "email": "Email",
        "emailInvalid": "Ungültige E-Mail",
        "firstName": "Vorname",
        "language": "Sprache",
        "lastName": "Nachname",
        "password": "Passwort",
        "role": "Rolle",
        "submit": "Erstellen",
        "submitEdit": "Anwenden",
        "title": "Neuer Benutzer",
        "titleEdit": "Benutzer bearbeiten",
        "username": "Benutzername"
    },
    "fr": {
        "actions": "Actions",
        "admin": "Administrateur",
        "email": "E-mail",
        "emailInvalid": "E-mail invalide",
        "firstName": "Prénom",
        "language": "Langue",
        "lastName": "Nom",
        "password": "Mot de passe",
        "role": "Rôle",
        "submit": "Créer",
        "submitEdit": "Appliquer",
        "title": "Nouvel utilisateur",
        "titleEdit": "Modifier l'utilisateur",
        "username": "Nom d'utilisateur"
    },
    "it": {
        "admin": "Amministratore",
        "email": "E-mail",
        "emailInvalid": "E-Mail non valido",
        "firstName": "Prenome",
        "language": "Lingue",
        "lastName": "Cognome",
        "password": "Password",
        "role": "Ruolo",
        "submit": "Creare",
        "submitEdit": "Applicare",
        "title": "Nuovo utente",
        "titleEdit": "Modifica utente",
        "username": "Nome utente"
    }
}
</i18n>

<style lang="scss">
.create-user-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-gray-lighter-new;
    font-size: 14px;
    overflow-y: auto;

    &__content {
        flex: 1 0 auto;
        margin-bottom: 68px;
    }

    &__actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid $color-gray-light-new;
        background-color: #fff;
    }

    .settings-form {
        width: 100%;
        padding: 24px 16px;
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        font-size: 14px;
        color: $color-text-new;
        transition: all 0.2s;
    }
}
</style>
